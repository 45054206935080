<template lang="pug">
//- t-card(:color="color" :showClose="notEmptyCart" v-on="$listeners" body-height="300px" :editMode="editMode")
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Scanner
  v-skeleton-loader(type="list-item-two-line" boilerplate)
  p.px-4.text-center.font-weight-thin Необходимо добавить хотя бы один
    br
    | товар чтобы сформировать чек
  .qrviewfinder
  template(slot="footer")
    t-btn(@click="$emit('route','cart',true)") Scan

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
  },
  props: {
    nobasket: {
      type: Boolean,
      default: undefined,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    // editMode: {
    //   type: Boolean,
    //   default: false,
    //   required: false,
    // },
    color: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    editMode: false,
    showDialog: false,
    show: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),
  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
    notEmptyCart() { return this.cart.length > 0 },
    total() {
      return this.cart.reduce((acc, i) => acc + i.commodity.total, 0)
    },
  },
  created() {
  },
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
      addItem: 'tjtheme/addItem',
    }),
    editItem(item) {
      console.log('editItem', item)
    },
  },
}
</script>
<style lang="stylus">

.qrviewfinder
  height: 200px;
  width: 200px;
  margin 50px auto;
  background: linear-gradient(red, red) top left,
              linear-gradient(red, red) top left,
              linear-gradient(red, red) top right,
              linear-gradient(red, red) top right,
              linear-gradient(red, red) bottom left,
              linear-gradient(red, red) bottom left,
              linear-gradient(red, red) bottom right,
              linear-gradient(red, red) bottom right;
  background-size: 2px 20px, 20px 2px;
  background-repeat: no-repeat;

</style>
